export default {
  "globalNavBar": {
    "pc": {
      "menu": {
        "left": {
          "service": {
            "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 소개"])},
            "demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의하기"])}
          },
          "introduce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회사 소개"])},
          "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객 사례"])},
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가격 안내"])}
        },
        "right": {
          "loginButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 로그인"])},
          "applyButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의하기"])}
        }
      }
    },
    "mobile": {
      "menu": {
        "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 소개"])},
        "demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의하기"])},
        "introduce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회사 소개"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객 사례"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가격 안내"])}
      },
      "bottomButton": {
        "login_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])},
        "apply_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의하기"])}
      }
    }
  },
  "home": {
    "section1": {
      "title": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상시 QA 테스트 업무,"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HBsmith에게 맡기세요~!"])}
      },
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테스트와 관련된 항목들만 알려 주시면, QA 환경 세팅부터 결과 알림까지 HBsmith가 전부 대신해드립니다."])}
    },
    "section2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객사"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객의 성공적인 서비스 운영을 돕고 있습니다."])},
      "card1": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["롯데온 - 테스트 자동화 도입 사례"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“자동화 테스트는 서비스의 신뢰성을 높여줘요."])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HBsmith 서비스 도입으로 문제가 발생 했을 때 테스트 결과 알림을 통해 "])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정확하고 빠르게 원인 파악을 할 수 있습니다.”"])}
      },
      "card2": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["도그 메이트 - 테스트 자동화 도입 사례"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“연휴 전에 중요한 기능 오픈을 해야 하는 일이 있었는데, "])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["에러 발생 여부를 확인하고 배포하여"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평안한 연휴를 보낼 수 있었습니다”"])}
      },
      "card3": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["크레마 - 테스트 자동화 도입 사례"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“60단계 QA test는 "])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두 인공지능 봇에게 맡기고, "])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이제는 신규 기능에 더집중하고 있습니다.”"])}
      },
      "card4": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["식스샵 - 테스트 자동화 도입 사례"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“모든 기능을 손으로 테스트 했었습니다. "])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HBsmith 서비스 도입 후"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["창의적인 업무에 집중할 수 있어요.”"])}
      },
      "reviewMoreButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이용 후기 더 보러 가기"])}
    },
    "section3": {
      "title": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비즈니스에 필요한 "])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HBsmith 제품을 확인해보세요."])}
      },
      "card1": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“모든 기능 테스트를 "])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["진행하기에는"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시간과 사람"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 부족해요”"])}
      },
      "card2": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“중요도에 따라 "])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["급한 테스트"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["를 진행하느라"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 많은 테스트를 놓치고 있어요”"])}
      }
    },
    "section5": {
      "title": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HBsmith는 서비스의 성공적인 운영을 위해"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금도 테스트"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 하고 있습니다."])}
      },
      "mobile": {
        "post1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["한 눈에 확인하는 테스트 결과"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테스트 결과 URL로 사전 조건, 결과 확인 가능"])}
        },
        "post2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15개 국가, 200개 이상 테스트 환경 지원"])},
          "subtitle": {
            "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["호주, 바레인, 브라질, 캐나다, 프랑스, 독일, 홍콩, 영국, 미국 등 15개 국가 "])},
            "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Android, iOS, Window, Chrome, Explorer, Safari 등"])},
            "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필요한 환경에 맞춘 테스트 가능"])}
          }
        },
        "post3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["효율적인 Dashboard"])},
          "subtitle": {
            "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테스트 진행 상황에 대한 주요 내용을 "])},
            "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실시간, 기간별로 한눈에 확인"])}
          }
        },
        "post4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장애 발생 시 빠른 알림"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카카오톡, 전화, 문자, 이메일, 슬랙, 잔디, webex 알림 지원"])}
        }
      },
      "pc": {
        "tab": {
          "button1": {
            "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["한 눈에 확인하는 "])},
            "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 테스트 결과"])}
          },
          "button2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테스트 환경 지원"])},
          "button3": {
            "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["효율적인 "])},
            "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])}
          },
          "button4": {
            "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장애 발생 시 "])},
            "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 빠른 알림"])}
          }
        },
        "box1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["한 눈에 확인하는 테스트 결과"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테스트 결과 URL로 사전 조건, 결과 확인 가능"])}
        },
        "box2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15개 국가, 200개 이상 테스트 환경 지원"])},
          "subtitle": {
            "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["호주, 바레인, 브라질, 캐나다, 프랑스, 독일, 홍콩, 영국, 미국…등 15개 국가 "])},
            "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Android, iOS, Window, Chrome, Explorer, Safari 등, "])},
            "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필요한 환경에 맞춘 테스트 가능"])}
          }
        },
        "box3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["효율적인 Dashboard"])},
          "subtitle": {
            "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테스트 진행 상황에 대한 주요 내용을 "])},
            "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실시간, 기간별로 한눈에 확인"])}
          }
        },
        "box4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장애 발생 시 빠른 알림"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카카오톡, 전화, 문자, 이메일, 슬랙, 잔디, webex 알림 지원"])}
        }
      }
    },
    "section6": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객이 HBsmith를 선택한 이유는 무엇일까요?"])},
      "subtitle": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HBsmith는 시간, 비용적 효과라는 두마리 토끼를 잡았습니다. "])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["긴 시간의 검수 과정들이 간결해지고, 일의 효율성과 정확성이 높아집니다."])}
      },
      "box1": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5명이 16시간동안 수행했던 테스트 케이스를"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1시간"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["으로 단축할 수 있습니다."])}
      },
      "box2": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유지보수 "])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비용"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["을 절감할 수 있습니다. "])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사람과 비교했을 때, "])},
        "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2배 이상의 효과"])},
        "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["를 볼 수 있습니다."])}
      }
    },
    "section7": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HBsmith 서비스 도입 프로세스"])},
      "process1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 컨설팅"])},
        "list1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["도입 검토"])},
          "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 서비스 소개 온라인/오프라인 미팅"])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 견적 및 계약 진행"])},
          "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 데모 등록"])}
        },
        "list2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동화 범위 선정 및 계획"])},
          "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 테스트 케이스 확인"])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 견적 및 계약 진행"])},
          "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 리소스 확보"])},
          "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 일정 계획 수립"])}
        }
      },
      "process2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 테스트 자동화"])},
        "list1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테스트 등록 및 실행"])},
          "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 테스트 환경 및 솔루션 셋팅"])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 자동화 테스트 등록"])},
          "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 기능/UI 등 테스트"])}
        },
        "list2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결과 확인"])},
          "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 테스트 결과 확인"])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 실시간/기간별 대시보드 확인"])},
          "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 테스트 결과 리뷰"])}
        },
        "list3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수정 반영"])},
          "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 테스트 개선사항 조치(재등록 등)"])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 신규 job 생성(테스트 결과 반영)"])}
        }
      },
      "process3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 운영 관리"])},
        "list1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["운영 및 유지보수"])},
          "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 테스트 리소스 운영 관리(2개월)"])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 서비스 헬스 체크"])}
        }
      }
    },
    "section8": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의하기"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*이름"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*업무용 이메일"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*전화번호"])},
      "serviceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스명(URL, App)"])},
      "etc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기타 문의"])},
      "marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HBsmith에서 발송하는 마케팅 정보 수신에 동의합니다."])},
      "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*개인정보 처리방침에 동의합니다."])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제출하기"])}
    }
  },
  "introduce": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회사 소개"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA 테스트 자동화 대행 서비스, HBsmith"])},
    "section1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vision"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“고객사 서비스의 성공적 운영을 돕는다.”"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인공지능 QA 테스트 대행 서비스 HBsmith는 “고객사 서비스의 성공적 운영을 돕는다” 라는 비전으로 2017년 4월에 창립되었습니다."])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지속적으로 성장하는 온라인 서비스에 많은 사용자들이 방문하고, 기대하는 품질은 높아져 가고있습니다."])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새로운 아이디어를 개발하고, 무중단으로 릴리즈하는 온라인 서비스 환경에서 발생하는 문제를"])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(주)에이치비스미스는 24시간 365일 사람처럼 테스트 하고 알려주는 인공지능 서비스를 개발하는데 매진해 왔습니다."])},
      "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["국내를 넘어 글로벌 온라인 서비스 품질을 책임지는 길, 국경 없는 온라인 서비스를 위한 그 길을, "])},
      "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(주)에이치비스미스는 큰 꿈을 갖고 걸어가려 합니다."])}
    },
    "section2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
      "year2021": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신한스퀘어브릿지서울 인큐베이션 7기 선정"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Series A 투자 유치 (인탑스 인베스트먼트, 하나벤처스, 벤처스퀘어, 핀델문도파트너스, 개인투자자)"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미국 법인 "])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" BizFlow 유료 사용 계약"])},
        "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 티맵모빌리티 유료 사용 계약"])}
      },
      "year2020": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 롯데쇼핑 유료 사용 계약"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS 정글 프로그램 선정 - 과제번호 : 10367054"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pre-A 2 투자 유치 (인탑스 인베스트먼트, 에버그린 투자 파트너스, 벤처스퀘어, 핀델문도 파트너스, 개인투자자)"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제 2회 경동인베스트 오픈이노베이션 챌린지 ‘Challenge상’(3위) 수상"])},
        "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["특허 등록 10-2145821 ‘서비스 플랫폼을 테스트 하기 위한 장치, 시스템 및 방법’"])},
        "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["하나은행 1Q Agile Lab 10기 선정"])},
        "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBK창공 구로 4기 선정"])},
        "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["한국지능정보시스템학회 2020 춘계학술대회 인텔리전스 대상 지능형 스타트업 부문 우수상"])},
        "text9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI 바우처 지원사입 공급기업 등록"])}
      },
      "year2019": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIPS 투자 유치"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pre-A 투자 유치(벤처스퀘어, 핀덴문도 파트너스, 에버그린 투자 파트너스, 개인투자자)"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KAIST 창업어워드 우수팀 수상"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angel 투자 유치"])}
      },
      "year2018": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["벤처기업 인증"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS 기술파트너사 선정"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘HBSMITH’ 상표 등록"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정식 서비스 시작"])}
      },
      "year2017": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BETA 서비스 시작"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primer seed 투자 유치"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["법인 사업자 설립"])}
      },
      "year2016": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["한종원, 윤제상 사업 시작 결의"])}
      }
    },
    "section3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22억 규모 시리즈A 투자 유치"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA 테스트도 AI로"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인력난 해결책 제시"])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["온택트페어 2021 참가"])},
      "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["국내 QA 테스트 시장 공략 강화"])},
      "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월 QA 테스트 수행 2배 증가"])}
    },
    "section4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["특허, 파트너사 및 수상 경력"])},
      "partner": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“서비스 플랫폼을 테스트하기 위한 장치, 시스템 및 방법” 특허 등록(2020)"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록 번호 : 10-2145821"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS 공식 기술 파트너사 (since 2018)"])}
      },
      "career": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수상 경력"])},
        "history1": {
          "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제 2회 경동인베스트 "])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오픈이노베이션 챌린지"])},
          "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Challenge"])},
          "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020년"])}
        },
        "history2": {
          "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["한국지능정보시스템학회 "])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020 춘계 학술대회"])},
          "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우수상"])},
          "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인텔리전스대상 지능형 스타트업 부문"])}
        },
        "history3": {
          "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KAIST 창업어워드"])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우수팀"])},
          "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2019년"])}
        }
      }
    }
  },
  "review": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객 사례"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA 테스트 자동화 대행 서비스, HBsmith"])},
    "section1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객 사례"])},
      "review1": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1,600개의 고객사 CREMA"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["60단계 QA 테스트 자동화"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“서비스 배포 전 HBsmith 서비스로 검증 단계를 자동화 구축하여 단순, 반복적인 QA 기능 테스트에서 벗어나 다른 일에 더 많은 리소스를 할당하고 있습니다.”"])}
      },
      "review2": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["반려인 6만명이 선택한 도그메이트"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연휴 전 크리티컬한 장애 사전에 발견"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“연휴 전에 중요한 기능 오픈을 해야 하는 일이 있었는데, 에러가 발생하지 않는 여부를 확인하고 배포하여 평안한 연휴를 보낼 수 있었습니다.”"])}
      },
      "review3": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최의철 CTO"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6분 만에 만드는 멋진 쇼핑몰 식스샵 "])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제, 적립금 등 24시간 365일 테스트"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“실제 운영 환경에서 잘 되는 게 제일 중요한데 일정 시간 안에 문제가 생기더라도 인지하고 해결해서 운영 할 수 있어요.”"])}
      }
    },
    "section2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객사"])}
    }
  },
  "price": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가격 안내"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비즈니스에 적합한 요금제를 알아보세요."])},
    "starter": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter Plan"])},
      "content": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소규모 서비스 상시 QA를 위한 서비스"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만원 / 월"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 월 100시간"])},
        "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(서비스 등록 및 테스트 수행 시간)"])},
        "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PC Browser"])}
      }
    },
    "business": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Plan"])},
      "content": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["성장하는 비즈니스를 위한 자동화 테스트"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["180"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만원 / 월"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 월 220시간"])},
        "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(서비스 등록 및 테스트 수행 시간)"])},
        "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PC Browser"])},
        "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24시간 QA 관제 서비스"])}
      },
      "option": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["더 필요한 옵션이 있으신가요?"])},
        "option1": {
          "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Android / iOS 모바일 기기"])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Android / iOS 실 기기를 제공 해드립니다. Android 실 기기 1대는 "])},
          "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["무료"])},
          "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 제공 해드립니다."])},
          "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3대"])},
          "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5대"])}
        },
        "option2": {
          "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium Support"])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium Support로 차별화된 서비스를 받을수 있습니다. 분야별 자동화 테스트 전문가가 시나리오 우선 등록 및 변경된 기능에 대해 최우선 지원합니다."])},
          "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0.5M/M"])},
          "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1M/M"])},
          "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2M/M"])}
        },
        "option3": {
          "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Case Templete"])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테스트 시나리오 작성이 어려우신가요? HBsmith에서 테스트 시나리오를 작성해드립니다."])},
          "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100개 이하"])},
          "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["250개 이하"])}
        },
        "option4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["본인 인증 문자 서비스"])},
        "option5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["은행 입/출금 테스트"])}
      }
    },
    "enterprise": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise Plan"])},
      "content": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객 서비스 맞춤형 서비스"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["영업 문의"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전담 서비스 매니저 관리"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대량 테스트 계획에 따른 추가 혜택 / 할인"])},
        "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chrome, IE11, Firefox, Safari…등"])},
        "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전담 Account Manager"])},
        "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA 인력 지원"])},
        "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실시간, 기간별 대시보드"])},
        "text9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화 및 온라인 알림 지원"])}
      },
      "option": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["더 필요한 옵션이 있으신가요?"])},
        "option1": {
          "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테스트 환경, 국가별 지원, Custom 대시보드…등 추가적인 옵션에 대한 협의가 가능합니다."])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["견적 문의하기"])}
        }
      }
    }
  },
  "footNavBar": {
    "menu": {
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 이용 약관"])},
      "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인 정보 처리방침"])},
      "serviceStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Status"])}
    },
    "detail1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["에이치비스미스 ", _interpolate(_named("sep")), " 사업자등록번호: 291-88-00604"])},
    "detail2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["개인정보보호관리책임자: 윤제상 ", _interpolate(_named("sep")), " 대표자: 한종원"])},
    "detail3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화번호:"])},
    "detail4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["070-4280-9333"])},
    "detail5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["경기도 안양시 동안구 경수대로623번길 46, 108-403"])},
    "detail7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주식회사 에이치비스미스"])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© 2024 All rights reserved."])}
  }
}