import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/ko/Home.vue';

const children = [
  {
    path: '/',
    name: 'Home',
    alias: ['/home', '/index.html'],
    component: Home,
  },
  {
    path: '/introduce',
    name: 'Introduce',
    component: () => import(/* webpackChunkName: "introduce" */ '@/views/ko/Introduce.vue'),
  },
  {
    path: '/review',
    name: 'Review',
    component: () => import(/* webpackChunkName: "review" */ '@/views/ko/Review.vue'),
  },
  {
    path: '/price',
    name: 'Price',
    component: () => import(/* webpackChunkName: "price" */ '@/views/ko/Price.vue'),
  },
  {
    path: '/en/',
    name: 'HomeEn',
    alias: ['/en/home', '/en/index.html'],
    component: () => import(/* webpackChunkName: "introduce" */ '@/views/en/Home.vue'),
  },
  {
    path: '/en/introduce',
    name: 'IntroduceEn',
    component: () => import(/* webpackChunkName: "introduce" */ '@/views/en/Introduce.vue'),
  },
  {
    path: '/en/review',
    name: 'ReviewEn',
    component: () => import(/* webpackChunkName: "review" */ '@/views/en/Review.vue'),
  },
  {
    path: '/en/price',
    name: 'PriceEn',
    component: () => import(/* webpackChunkName: "price" */ '@/views/en/Price.vue'),
  },
  {
    path: '/:match(.*)*',
    name: 'NotFound',
    component: Home,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [{
    path: '/:locale(en|ko)?',
    name: 'locale',
    component: () => import(/* webpackChunkName: "locale" */ './Locale.vue'),
    children,
  }],
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
