<style>

.loc_select {
  height:70px;
  line-height: 45px;
  text-align: center;
  font-weight: 800;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0);
  margin-left:20px;
}

.loc_select div{
  padding:10px;
}

.loc_select img {
  margin-left: 10px;
  vertical-align: middle;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.local-menu{
  position: relative;
}

.local-menu > .local-sub-menu {
  position: absolute;
  right:0;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  display: none;
}

.local-sub-menu li:hover{
  color:#4e76ff;
}

.local-menu:hover .local-sub-menu {
  display: block;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgb(36 42 65 / 20%);
  padding: 10px 0;
}

.local-menu > .local-sub-menu > li {
  width:80px;
  height:36px;
  line-height: 36px;
  padding: 0px 10px;
}

.local-menu > .local-sub-menu img {
  margin-right: 5px;
  vertical-align: middle;
}

.m-loc-menu{
  position: absolute;
  text-align: right;
  right:16px;
  bottom:16px;
  height:40px;

}

.m-loc-menu div{
  display: inline-block;
  padding:10px 20px;
  text-align: center;
  font-size:14px;
  font-weight: 800;
  color: #c6c6c6;
}

.m-loc-menu div.active{
  color: #000;
}

.m-loc-menu span{
  color:#d8d8d8;
  font-size:10px;
}

</style>

<template>
  <div id="header_wrap">
    <header class="header">
      <h1 class="logo">
        <a href="home"><img src="@/assets/images/black-logo.svg" /></a>
      </h1>
      <nav class="pc_nav">
        <ul class="gnb">
          <li>
            <a class="gnb-submenu cursor-pointer">{{ $t('globalNavBar.pc.menu.left.service.service') }}</a>
            <img src="@/assets/images/icon-gray-arrow.svg" alt="" />
            <div class="gnb-box d-none">
              <p><a href="home">{{ $t('globalNavBar.pc.menu.left.service.service') }}</a></p>
              <p><a href="home#sec_08">{{ $t('globalNavBar.pc.menu.left.service.demo') }}</a></p>
            </div>
          </li>
          <li><a href="introduce">{{ $t('globalNavBar.pc.menu.left.introduce') }}</a></li>
          <li><a href="review">{{ $t('globalNavBar.pc.menu.left.review') }}</a></li>
          <li>
            <a href="price" class="cursor-pointer">{{ $t('globalNavBar.pc.menu.left.price') }}</a>
          </li>
        </ul>
        <ul class="gnb-r">
          <li><a href="https://app.hbsmith.io/auth/signin" class="login_btn">{{ $t('globalNavBar.pc.menu.right.loginButton') }}</a></li>
          <li><a href="home#sec_08" class="apply_btn">{{ $t('globalNavBar.pc.menu.right.applyButton') }}</a></li>

          <!-- <li class="local-menu">
            <div class="loc_select cursor-pointer">
              <div v-if="$i18n.locale === 'ko'">KR<img src="@/assets/images/nav_arrow_black.svg"/></div>
              <div v-if="$i18n.locale === 'en'">EN<img src="@/assets/images/nav_arrow_black.svg"/></div>
            </div>
            <ul class="local-sub-menu">
              <li class="cursor-pointer" v-on:click="onClickKorean">KR(한국어)</li>
              <li class="cursor-pointer" v-on:click="onClickEnglish">EN(영어)</li>
            </ul>
          </li> -->

        </ul>
      </nav>
      <span class="menu-toggle-btn">
        <img src="@/assets/images/icon-menu.svg" alt="" />
      </span>
    </header>
    <nav class="m_nav">
      <ul>
        <li>
          <a href="home">
            <span>{{ $t('globalNavBar.mobile.menu.service') }}</span>
            <span><img src="@/assets/images/b-icon-arrow.png" alt="" /></span>
          </a>
          <a href="home#sec_08">
            <span>{{ $t('globalNavBar.mobile.menu.demo') }}</span>
            <span><img src="@/assets/images/b-icon-arrow.png" alt="" /></span>
          </a>
          <a href="introduce">
            <span>{{ $t('globalNavBar.mobile.menu.introduce') }}</span>
            <span><img src="@/assets/images/b-icon-arrow.png" alt="" /></span>
          </a>
          <a href="review">
            <span>{{ $t('globalNavBar.mobile.menu.review') }}</span>
            <img src="@/assets/images/b-icon-arrow.png" alt="" />
          </a>
          <a href="price">
            <span>{{ $t('globalNavBar.mobile.menu.price') }}</span>
            <span><img src="@/assets/images/b-icon-arrow.png" alt="" /></span>
          </a>
        </li>
      </ul>
      <div class="bottom_btns">
        <a href="home#sec_08" class="apply_btn">{{ $t('globalNavBar.mobile.bottomButton.apply_btn') }}</a>
        <a href="https://app.hbsmith.io/auth/signin" class="login_btn">{{ $t('globalNavBar.mobile.bottomButton.login_btn') }}</a>
      </div>
      <div class="m-local">
          <div class="m-loc-menu">
            <div class="cursor-pointer" v-bind:class="{ active: $i18n.locale === 'ko' }" v-on:click="onClickKorean">KR</div>
            <span>|</span>
            <div class="cursor-pointer" v-bind:class="{ active: $i18n.locale === 'en' }" v-on:click="onClickEnglish">EN</div>
          </div>
      </div>
    </nav>
  </div>
</template>

<script>
import $ from 'jquery';
import LOGO_BLACK from '@/assets/images/black-logo.svg';
import LOGO_WHITE from '@/assets/images/logo.svg';
import ICON_BLACK_ARROW from '@/assets/images/icon-black-arrow.svg';
import ICON_GRAY_ARROW from '@/assets/images/icon-gray-arrow.svg';
import ICON_BLUE_ARROW from '@/assets/images/icon-blue-arrow.svg';
import ICON_DEFAULT_ARROW from '@/assets/images/icon-arrow.svg';
import ICON_MENU_DEFAULT from '@/assets/images/icon-menu.svg';
import ICON_MENU_BLUE from '@/assets/images/icon-menu-blue.svg';
import ICON_CLOSE from '@/assets/images/icon-close.svg';

export default {
  name: 'GlobalNavBar',
  watch: {
    $route(to, from) {
      if ((to.path === from.path) && (to.path !== '/')) {
        return;
      }

      const { path } = to;
      const paths = path.split('/');
      const context = paths[paths.length - 1];

      switch (context) {
        case 'introduce':
        case 'review':
          this.fontColor = 'white';
          this.logo = LOGO_WHITE;
          this.initOfWhite();
          break;
        case '':
        case 'home':
        case 'price':
        default:
          this.fontColor = 'black';
          this.logo = LOGO_BLACK;
          this.initOfBlack();
      }
    },
  },
  data() {
    return {
      fontColor: 'white',
      logo: LOGO_WHITE,
    };
  },
  methods: {
    onClickKorean() {
      const paths = window.location.pathname.split('/');
      const lastPath = paths[paths.length - 1];
      window.location.replace(`/${lastPath}`);
    },
    onClickEnglish() {
      const paths = window.location.pathname.split('/');
      const lastPath = paths[paths.length - 1];
      window.location.replace(`/en/${lastPath}`);
    },
    initOfBlack() {
      let menuToggle = false;

      $(window).resize(function () {
        $('.gnb-box').addClass('d-none');
      });

      $('.gnb-submenu').hover(function () {
        if ($('.gnb-box').hasClass('d-none')) {
          $('.gnb-box').addClass('d-none');
          const position = $(this).position();
          $(this).siblings('.gnb-box').css('left', position.left);
          $(this).siblings('.gnb-box').removeClass('d-none');
        }
      }, function () {
        if ($('.gnb-box:hover').length === 0) {
          $('.gnb-box').addClass('d-none');
        }
      });

      $('.gnb-box').hover(function () {}, function () {
        $('.gnb-box').addClass('d-none');
      });

      $(document).click((event) => {
        if (!$(event.target).is('.gnb-submenu')) {
          $('.gnb-box').addClass('d-none');
        }
      });

      $('.header nav ul.gnb li > a').hover(function () {
        const scrollValue = $(document).scrollTop();

        if (scrollValue >= $('.header').height()) {
          $(this).css('color', '#5479f7'); // 스크롤 내리고 hover 시
          $(this).css('font-weight', '500');
          $(this).siblings('img').attr('src', ICON_BLUE_ARROW);
        } else {
          $(this).css('color', '#5479f7'); // Top에서 hover 시
          $(this).css('font-weight', '500');
          $(this).siblings('img').css('opacity', '1');
          $(this).siblings('img').attr('src', ICON_BLUE_ARROW);
        }
      }, function () {
        const scrollValue = $(document).scrollTop();

        if (scrollValue >= $('.header').height()) {
          $(this).css('color', '#000'); // 스크롤 내리고 hover 후 뗐을 때
          $(this).css('font-weight', '400');
          $(this).siblings('img').attr('src', ICON_BLACK_ARROW);
        } else {
          $(this).siblings('img').css('opacity', '0.7'); // Top에서 hover 후 뗐을 때
          $(this).css('color', '#000');
          $(this).css('font-weight', '400');
          $(this).siblings('img').attr('src', ICON_BLACK_ARROW);
        }
      });

      $('.header nav ul.gnb-r .apply_btn').hover(function () {
        const scrollValue = $(document).scrollTop();
        if (scrollValue >= $('.header').height()) {
          $(this).addClass('apply_btn_hover');
        } else {
          $(this).addClass('apply_btn_hover_top');
        }
      }, function () {
        $(this).removeClass('apply_btn_hover');
        $(this).removeClass('apply_btn_hover_top');
      });

      $('.header nav ul.gnb-r .login_btn').hover(function () {
        const scrollValue = $(document).scrollTop();
        if (scrollValue >= $('.header').height()) {
          $(this).addClass('login_btn_hover');
        } else {
          $(this).addClass('login_btn_hover_top');
        }
      }, function () {
        $(this).removeClass('login_btn_hover');
        $(this).removeClass('login_btn_hover_top');
      });

      $('.menu-toggle-btn').click(function () {
        menuToggle = !menuToggle;
        if (menuToggle === true) {
          $('.m_nav').on('scroll touchmove mousewheel', (event) => {
            event.preventDefault();
            event.stopPropagation();
            return false;
          });
          $('.menu-toggle-btn > img').attr('src', ICON_CLOSE);
          $('.logo > a > img').attr('src', LOGO_BLACK);
        } else {
          $('.m_nav').off('scroll touchmove mousewheel');

          const scrollValue = $(document).scrollTop();
          if (scrollValue >= $('.header').height()) {
            $('.logo > a > img').attr('src', LOGO_BLACK);
            $('.menu-toggle-btn > img').attr('src', ICON_MENU_BLUE);
          } else {
            $('.logo > a > img').attr('src', LOGO_BLACK);
            $('.menu-toggle-btn > img').attr('src', ICON_MENU_BLUE);
          }
        }
        $('.m_nav').stop().slideToggle('fast');
      });

      $('.m_nav a').click(function () {
        if (menuToggle === true) {
          $('.menu-toggle-btn').trigger('click');
        }
      });

      $('.right_btn #btn_top').click(function () {
        $('html, body').stop().animate({ scrollTop: 0 }, 'fast');
      });

      function checkScroll() {
        const scrollValue = $(document).scrollTop();
        $('.gnb-box').addClass('d-none');

        if (scrollValue >= $('.header').height()) {
          $('#header_wrap').css('background-color', '#ffffff');
          $('#header_wrap').css('box-shadow', '0 0 8px 0 rgba(36, 42, 65, 0.2)'); /* 메인페이지 */
          $('.header .logo a > img').attr('src', LOGO_BLACK);
          $('.header nav ul.gnb li > a').css('color', '#000000');
          $('.header nav ul.gnb li > a').css('opacity', '1');
          $('.header nav ul.gnb li > img').css('opacity', '1');
          $('.header nav ul.gnb-r li > a.login_btn').css('border-color', '#5479f7');
          $('.header nav ul.gnb-r li > a.login_btn').css('color', '#5479f7');
          // $('.header nav ul.gnb-r li > a.apply_btn').css('background-color', '#5479f7');
          // $('.header nav ul.gnb-r li > a.apply_btn').css('color', '#fff');
          $('.header nav ul.gnb li > img').attr('src', ICON_BLACK_ARROW);
          $('.header .menu-toggle-btn > img').attr('src', ICON_MENU_BLUE);
        } else {
          $('#header_wrap').css('background-color', 'transparent');
          $('#header_wrap').css('box-shadow', '0 0 8px 0 rgba(36, 42, 65, 0.0)');
          $('.header .logo a > img').attr('src', LOGO_BLACK);
          $('.header nav ul.gnb li > a').css('color', '#000000');
          $('.header nav ul.gnb li > a').css('font-weight', '400');
          $('.header nav ul.gnb li > a').css('opacity', '0.7');
          $('.header nav ul.gnb li > img').css('opacity', '0.7');
          $('.header nav ul.gnb-r li > a.login_btn').css('border-color', '#5479f7');
          $('.header nav ul.gnb-r li > a.login_btn').css('color', '#5479f7');
          // $('.header nav ul.gnb-r li > a.apply_btn').css('background-color', '#5479f7');
          // $('.header nav ul.gnb-r li > a.apply_btn').css('color', '#fff');
          $('.header nav ul.gnb li > img').attr('src', ICON_GRAY_ARROW);
          $('.header .menu-toggle-btn > img').attr('src', ICON_MENU_BLUE);
        }
      }

      $(window).scroll(function () {
        checkScroll();
      });

      $('.right_btn #btn_top').click(function () {
        $('html, body').stop().animate({ scrollTop: 0 }, 'fast');
      });

      checkScroll();
    },
    initOfWhite() {
      let menuToggle = false;

      $('.loc_select').css('color', 'white');

      $(window).resize(function () {
        $('.gnb-box').addClass('d-none');
      });

      $('.gnb-submenu').hover(function () {
        if ($(this).siblings('.gnb-box').hasClass('d-none')) {
          $('.gnb-box').addClass('d-none');
          const position = $(this).position();
          $(this).siblings('.gnb-box').css('left', position.left);
          $(this).siblings('.gnb-box').removeClass('d-none');
        }
      }, function () {
        if ($('.gnb-box:hover').length === 0) {
          $('.gnb-box').addClass('d-none');
        }
      });

      $('.gnb-box').hover(function () {}, function () {
        $('.gnb-box').addClass('d-none');
      });

      $('.service_tabs .btn_group button').click(function (e) {
        const target = $(e.target).attr('class');
        $('.service_tabs .btn_group button').removeClass('active');
        $('.service_tabs .contents .btn').removeClass('active');
        $(`.service_tabs .btn_group button.${target}`).addClass('active');
        $(`.box_${target}`).addClass('active');
      });

      $(document).click(function (event) {
        if (!$(event.target).is('.gnb-submenu')) {
          $('.gnb-box').addClass('d-none');
        }
      });

      $('.header nav ul.gnb li > a').hover(function () {
        const scrollValue = $(document).scrollTop();

        if (scrollValue >= $('.header').height()) {
          $(this).css('color', '#5479f7'); // 스크롤 내리고 hover 시
          $(this).css('font-weight', '500');
          $(this).siblings('img').attr('src', ICON_BLUE_ARROW);
        } else {
          $(this).css('color', '#fff'); // Top에서 hover 시
          $(this).css('font-weight', '500');
          $(this).siblings('img').css('opacity', '1');
          $(this).siblings('img').attr('src', ICON_DEFAULT_ARROW);
        }
      }, function () {
        const scrollValue = $(document).scrollTop();

        if (scrollValue >= $('.header').height()) {
          $(this).css('color', '#000'); // 스크롤 내리고 hover 후 뗐을 때
          $(this).css('font-weight', '400');
          $(this).siblings('img').attr('src', ICON_BLACK_ARROW);
        } else {
          $(this).siblings('img').css('opacity', '0.7');
          $(this).css('color', '#fff'); // Top에서 hover 후 뗐을 때
          $(this).css('font-weight', '400');
          $(this).siblings('img').attr('src', ICON_DEFAULT_ARROW);
        }
      });

      $('.header nav ul.gnb-r .apply_btn').hover(function () {
        const scrollValue = $(document).scrollTop();
        if (scrollValue >= $('.header').height()) {
          $(this).addClass('apply_btn_hover_sub');
        } else {
          $(this).addClass('apply_btn_hover_top_sub');
        }
      }, function () {
        $(this).removeClass('apply_btn_hover_sub');
        $(this).removeClass('apply_btn_hover_top_sub');
      });

      $('.header nav ul.gnb-r .login_btn').hover(function () {
        const scrollValue = $(document).scrollTop();
        if (scrollValue >= $('.header').height()) {
          $(this).addClass('login_btn_hover_sub');
        } else {
          $(this).addClass('login_btn_hover_top_sub');
        }
      }, function () {
        $(this).removeClass('login_btn_hover_sub');
        $(this).removeClass('login_btn_hover_top_sub');
      });

      $('.menu-toggle-btn').click(function () {
        menuToggle = !menuToggle;
        if (menuToggle === true) {
          $('.m_nav').on('scroll touchmove mousewheel', function (event) {
            event.preventDefault();
            event.stopPropagation();
            return false;
          });
          $('.menu-toggle-btn > img').attr('src', ICON_CLOSE);
          $('.logo > a > img').attr('src', LOGO_BLACK);
        } else {
          $('.m_nav').off('scroll touchmove mousewheel');

          const scrollValue = $(document).scrollTop();
          if (scrollValue >= $('.header').height()) {
            $('.logo > a > img').attr('src', LOGO_BLACK);
            $('.menu-toggle-btn > img').attr('src', ICON_MENU_BLUE);
          } else {
            $('.logo > a > img').attr('src', LOGO_WHITE);
            $('.menu-toggle-btn > img').attr('src', ICON_MENU_DEFAULT);
          }
        }
        $('.m_nav').stop().slideToggle('fast');
      });

      $('.m_nav a').click(function () {
        if (menuToggle === true) {
          $('.menu-toggle-btn').trigger('click');
        }
      });

      function checkScroll() {
        const scrollValue = $(document).scrollTop();
        $('.gnb-box').addClass('d-none');

        if (scrollValue >= $('.header').height()) {
          $('.loc_select').css('color', 'black');
          $('#header_wrap').css('background-color', '#ffffff');
          $('#header_wrap').css('box-shadow', '0 0 8px 0 rgba(36, 42, 65, 0.2)'); /* 고객사례 */
          $('.header .logo a > img').attr('src', LOGO_BLACK);
          $('.header nav ul.gnb li > a').css('color', '#000000');
          $('.header nav ul.gnb li > a').css('opacity', '1');
          $('.header nav ul.gnb li > img').css('opacity', '1');
          $('.header nav ul.gnb-r li > a.login_btn').css('border-color', '#5479f7'); /* 스크롤 내렸을 때 */
          $('.header nav ul.gnb-r li > a.login_btn').css('color', '#5479f7');
          $('.header nav ul.gnb-r li > a.apply_btn').css('background-color', 'rgb(84, 121, 247)');
          $('.header nav ul.gnb-r li > a.apply_btn').css('color', 'rgb(255, 255, 255)');
          $('.header nav ul.gnb li > img').attr('src', ICON_BLACK_ARROW);
          $('.header .menu-toggle-btn > img').attr('src', ICON_MENU_BLUE);
        } else {
          $('.loc_select').css('color', 'white');
          $('#header_wrap').css('background-color', 'transparent');
          $('#header_wrap').css('box-shadow', '0 0 8px 0 rgba(36, 42, 65, 0.0)');
          $('.header .logo a > img').attr('src', LOGO_WHITE);
          $('.header nav ul.gnb li > a').css('color', '#fff');
          $('.header nav ul.gnb li > a').css('opacity', '0.7');
          $('.header nav ul.gnb li > img').css('opacity', '0.7');
          $('.header nav ul.gnb-r li > a.login_btn').css('border-color', '#fff');
          $('.header nav ul.gnb-r li > a.login_btn').css('color', '#fff');
          $('.header nav ul.gnb-r li > a.apply_btn').css('background-color', 'rgb(255, 255, 255)');
          $('.header nav ul.gnb-r li > a.apply_btn').css('color', 'rgb(84, 121, 247)');
          $('.header nav ul.gnb li > img').attr('src', ICON_DEFAULT_ARROW);
          $('.header .menu-toggle-btn > img').attr('src', ICON_MENU_DEFAULT);
        }
      }

      $(window).scroll(function () {
        checkScroll();
      });

      $('.right_btn #btn_top').click(function () {
        $('html, body').stop().animate({ scrollTop: 0 }, 'fast');
      });

      checkScroll();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
