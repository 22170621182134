<template>
  <section class="footer">
    <div class="footer-wrap">
      <div class="footer-logo-wrap">
        <img class="logo" src="@/assets/images/logo.svg" alt="" />
        <p class="privacy">
          <a href="https://app2.hbsmith.io/static/terms.pdf" target="_blank">{{ $t('footNavBar.menu.terms') }}</a>
          <span>|</span>
          <a href="https://app2.hbsmith.io/static/privacy.pdf" target="_blank">{{ $t('footNavBar.menu.privacy') }}</a>
          <span>|</span>
          <a href="https://status.hbsmith.io/" target="_blank">{{ $t('footNavBar.menu.serviceStatus') }}</a>
        </p>
      </div>
      <p class="detail-text">
        {{ $t('footNavBar.detail1', { sep: '|' }) }}<br />
        {{ $t('footNavBar.detail2', { sep: '|' }) }}<br />
        {{ $t('footNavBar.detail3') }} <a :href="`tel:+82 ${$t('footNavBar.detail4')}`" class="tel">{{ $t('footNavBar.detail4') }}</a><br />
        {{ $t('footNavBar.detail5') }}<br />
      </p>
      <div class="social-box">
        <div class="copyright">{{ $t('footNavBar.copyright') }}</div>
        <div class="social-image-box">
          <a href="https://medium.com/hbsmith" target="_blank">
            <img src="@/assets/images/icon-medium@3x.png" alt="" />
          </a>
          <a href="https://www.facebook.com/hbsmith.page" target="_blank">
            <img src="@/assets/images/icon-facebook@3x.png" alt="" />
            </a>
          <a href="https://www.youtube.com/@hbsmith" target="_blank">
            <img src="@/assets/images/icon-youtube@3x.png" alt="" />
          </a>
          <a
            href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=hello@hbsmith.io"
            target="_blank"
          >
            <img src="@/assets/images/icon-email@3x.png" alt=""/>
          </a>
        </div>
      </div>
    </div>
  </section>
  <div class="right_btn">
    <p id="katalk">
      <a href="https://pf.kakao.com/_dyCvu/chat" target="_blank">
        <img src="@/assets/images/katalk.png" alt="카카오톡"/>
      </a>
    </p>
    <p id="btn_top">
      <a href="#top" class="btn-1">
        <img id="white" src="@/assets/images/btn-top.svg" alt="맨위로 이동" />
      </a>
      <a href="#top" class="btn-2">
        <img id="color" src="@/assets/images/btn-top_hover.svg" alt="맨위로 이동" />
      </a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'FootNavBar',
  mounted: () => {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
