<template>
  <GlobalNavBar />
  <router-view />
  <FootNavBar />
</template>

<script>
import GlobalNavBar from '@/components/layout/GlobalNavBar.vue';
import FootNavBar from '@/components/layout/FootNavBar.vue';

export default {
  name: 'App',
  components: {
    GlobalNavBar,
    FootNavBar,
  },
};
</script>

<style src="@/assets/css/app.css"></style>
