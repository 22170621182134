export default {
  "globalNavBar": {
    "pc": {
      "menu": {
        "left": {
          "service": {
            "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
            "demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Trial"])}
          },
          "introduce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
          "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Story"])},
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])}
        },
        "right": {
          "loginButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
          "applyButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Trial"])}
        }
      }
    },
    "mobile": {
      "menu": {
        "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
        "demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Trial"])},
        "introduce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Story"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])}
      },
      "bottomButton": {
        "login_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
        "apply_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Trial"])}
      }
    }
  },
  "home": {
    "section1": {
      "title": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA testing works,"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["leave it to HBsmith!"])}
      },
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have to worry about setting the environment to the result notification. We'll do it."])},
      "requestButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request a Demo"])}
    },
    "section2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["customer"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HBsmith has a wide range of customer testing know-how."])},
      "card1": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case1.LOTTE ON"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Automated testing increases the reliability of the service. "])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We can determine the cause accurately and quickly by choosing the HBsmith.”"])}
      },
      "card2": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case2. Dogmate"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“We had to do an important feature update before the holidays. "])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We were able to have a peaceful holiday season with HBsmith.”"])}
      },
      "card3": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case3.Crema"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“We left the 60-step QA test "])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to the AI bot and "])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["now we are concentrating on new features.”"])}
      },
      "card4": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case4.Sixshop"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“We tested all the features ourselves."])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["But after adopting HBsmith we can focus on "])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["our creative work.”"])}
      },
      "reviewMoreButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See more reviews"])}
    },
    "section3": {
      "title": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have a problem ilke this..."])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["how are you doing?"])}
      },
      "card1": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“We don't have "])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enough time and people "])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to run all the "])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["functional tests.”"])}
      },
      "card2": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“I miss "])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a lot of tests "])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["because I run tests in a hurry"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" according to their importance.”"])}
      }
    },
    "section4": {
      "title": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetitive functional testing, "])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["are you doing it by hand?"])}
      },
      "subtitle": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The HBsmith service is tested by an artificial intelligence robot at the customer's preferred time. "])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By simultaneously performing tests that used to be performed one by one by a person, work time can be effectively shortened."])}
      }
    },
    "section4-2": {
      "title": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality "])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of service "])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["leads to "])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["customer "])},
        "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trust "])},
        "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and "])},
        "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sales."])}
      },
      "subtitle": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preventing failures in advance and identifying the cause of operational problems quickly can improve service quality."])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HBsmith will overcome the limitations of simple, repeatable QA testing to help you run your customer service successfully."])}
      }
    },
    "section5": {
      "title": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How HBsmith "])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is helping "])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprises"])}
      },
      "mobile": {
        "post1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test results at a glance"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-conditions and results can be checked with the test result URL"])}
        },
        "post2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15 countries, 200+ test environments supported"])},
          "subtitle": {
            "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15 countries including Australia, Bahrain, Brazil, Canada, France, Germany, Hong Kong, UK, USA "])},
            "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Android, iOS, Window, Chrome, Explorer, Safari, etc"])},
            "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tests tailored to the required environment"])}
          }
        },
        "post3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efficient dashboard"])},
          "subtitle": {
            "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the main contents of the test progress "])},
            "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in real time and by period at a glance"])}
          }
        },
        "post4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick notification in case of failure"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KakaoTalk, phone, text message, email, slack, jANDI, webex notification support"])}
        }
      },
      "pc": {
        "tab": {
          "button1": {
            "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test results "])},
            "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" at a glance"])}
          },
          "button2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test environment support"])},
          "button3": {
            "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efficient "])},
            "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])}
          },
          "button4": {
            "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick notification "])},
            "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" in case of failure"])}
          }
        },
        "box1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test results at a glance"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-conditions and results can be checked with the test result URL"])}
        },
        "box2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15 countries, 200+ test environments supported"])},
          "subtitle": {
            "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Australia, Bahrain, Brazil, Canada, France, Germany, Hong Kong, UK, USA… 15 countries including "])},
            "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Android, iOS, Window, Chrome, Explorer, Safari, etc, "])},
            "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tests tailored to the required environment"])}
          }
        },
        "box3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efficient Dashboard"])},
          "subtitle": {
            "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the main contents of the test progress "])},
            "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in real time and by period at a glance"])}
          }
        },
        "box4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick notification in case of failure"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KakaoTalk, phone, text message, email, slack, jANDI, webex notification support"])}
        }
      }
    },
    "section6": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why did customers choose HBsmith?"])},
      "subtitle": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HBsmith caught two rabbits time and cost effectiveness. "])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Long-time inspection processes are simplified, and work efficiency and accuracy are increased."])}
      },
      "box1": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A test case performed by 5 people in 16 hours"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["can be reduced to"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 hour."])}
      },
      "box2": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance "])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["costs"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["can be reduced. "])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compared to humans, "])},
        "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you can see"])},
        "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more than twice the effect."])}
      }
    },
    "section7": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HBsmith Service Introduction Process"])},
      "process1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Consulting"])},
        "list1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduction review"])},
          "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Service Introduction Online/Offline Meeting"])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Quotation and contract progress"])},
          "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Demo registration"])}
        },
        "list2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automation scoping and planning"])},
          "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Check the test case"])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Quotation and contract progress"])},
          "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Securing resources"])},
          "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Schedule planning"])}
        }
      },
      "process2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Test automation"])},
        "list1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register and run tests"])},
          "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Test environment and solution setting"])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Automated test registration"])},
          "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Function/UI, etc. testing"])}
        },
        "list2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the result"])},
          "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Check the test results"])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Real-time/periodic dashboard check"])},
          "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Test result review"])}
        },
        "list3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reflection of corrections"])},
          "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Test improvement actions (re-registration, etc.)"])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Create a new job (reflect test results)"])}
        }
      },
      "process3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Operation management"])},
        "list1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation and maintenance"])},
          "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Test resource operation management (2 months)"])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Service health check"])}
        }
      }
    },
    "section8": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready to Get Started?"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you sent information for a demo, we’ll help your team."])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Name"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Email"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Phone number"])},
      "serviceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service name(URL, App)"])},
      "etc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other inquiries"])},
      "marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to receive marketing information from HBsmith."])},
      "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*I agree to the Privacy Policy."])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Sales"])}
    }
  },
  "introduce": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA test automation agency service, HBsmith"])},
    "section1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vision"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Helping the successful operation of customer service.”"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI QA testing agency service HBsmith was founded in April 2017 with the vision of “helping the successful operation of customer service”."])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Many users visit the continuously growing online service, and the expected quality is increasing."])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HBsmith has been dedicated to developing an artificial intelligence service that tests and informs people about problems that occur in the online service environment where new ideas are developed and released without interruption 24 hours a day, 365 days a year."])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HBsmith is about to walk the path of taking responsibility for global online service quality beyond the domestic market, the path for online service without borders, with a big dream."])},
      "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "section2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
      "year2021": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected for the 7th Shinhan Square Bridge Seoul Incubation"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Series A investment (Intops Investment, Hana Ventures, Venture Square, Findel Mundo Partners, individual investors)"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["US corporation "])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" BizFlow paid license agreement"])},
        "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Tmap Mobility paid license agreement"])}
      },
      "year2020": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Lotte Shopping paid license agreement"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS Jungle Program Selection - Assignment Number: 10367054"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-A 2 investment attraction (Intops Investment, Evergreen Investment Partners, Venture Square, Findelmundo Partners, individual investors)"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2nd Kyungdong Investment Open Innovation Challenge ‘Challenge Award’ (3rd place)"])},
        "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patent registration 10-2145821 ‘Device, system and method for testing service platform’"])},
        "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected for the 10th 1Q Agile Lab by Hana Bank"])},
        "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected as the 4th IBK Changgong Guro"])},
        "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellence Award in Intelligent Startup Division at the Intelligence Grand Prize at the 2020 Spring Conference of the Korean Institute of Intelligent Information Systems"])},
        "text9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered as a supplier with AI voucher support"])}
      },
      "year2019": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIPS Investment Attraction"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attracting pre-A investment (Venture Square, Findenmundo Partners, Evergreen Investment Partners, individual investors)"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KAIST Startup Award Excellent Team"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angel Investment Attraction"])}
      },
      "year2018": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venture company certification"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS Technology Partner Selection"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered ‘HBSMITH’ trademark"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start full service"])}
      },
      "year2017": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start BETA service"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attract Primer seed investment"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establishment of corporate business"])}
      },
      "year2016": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Han Jong-won and Yoon Je-sang decided to start business"])}
      }
    },
    "section3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attracted 2.2 billion Series A investment"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA testing with AI"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proposal of a solution to the shortage of manpower"])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participated in Ontact Fair 2021"])},
      "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reinforcement of the domestic QA test market"])},
      "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doubling monthly QA testing"])}
    },
    "section4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patents, Partners and Awards"])},
      "partner": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Apparatus, system and method for testing service platform” patent(2020)"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration number: 10-2145821"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS Official Technology Partner (since 2018)"])}
      },
      "career": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awards"])},
        "history1": {
          "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The 2nd Kyungdong Invest "])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Innovation Challenge"])},
          "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Challenge"])},
          "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020"])}
        },
        "history2": {
          "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korea Institute of Intelligent Information Systems "])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020 Spring Conference"])},
          "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellence Award"])},
          "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intelligent Startup Category at the Intelligence Awards"])}
        },
        "history3": {
          "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KAIST Startup Award"])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent team"])},
          "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2019"])}
        }
      }
    }
  },
  "review": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Story"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA test automation agency service, HBsmith"])},
    "section1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Story"])},
      "review1": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1,600 customers CREMA"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["60-step QA test automation"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“By building automated verification steps with HBsmith service before service deployment, we are dedicating more resources to other tasks, away from simple, repetitive QA functional testing.”"])}
      },
      "review2": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["60,000 user's choice, “Dog mates” "])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detect critical disability in advance before the holidays"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“There was a case where it was important to open before the holiday, but I was able to have a peaceful holiday by checking whether an error did not occur and distributing it.”"])}
      },
      "review3": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choi, Eui-cheol CTO"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy shopping mall creation, “Six Shop” "])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24/7 testing, including payment and savings"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“It is most important to do well in the actual operating environment, and even if a problem occurs within a certain period of time, we can recognize it, solve it, and operate it.”"])}
      }
    },
    "section2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])}
    }
  },
  "price": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price Guide"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find the plan that's right for your business."])},
    "starter": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter Plan"])},
      "content": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services for small, real-time QA"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1,000"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dollar / Month"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 hours per month"])},
        "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Service registration and test execution time)"])},
        "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PC Browser"])}
      }
    },
    "business": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Plan"])},
      "content": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automated testing for growing businesses"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1,800"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dollar / Month"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["220 hours per month"])},
        "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Service registration and test execution time)"])},
        "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PC Browser"])},
        "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 hour QA control service"])}
      },
      "option": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need more options?"])},
        "option1": {
          "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Android / iOS Mobile Device"])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Android / iOS We provide real equipment. One Android real device "])},
          "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["free"])},
          "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3EA"])},
          "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5EA"])}
        },
        "option2": {
          "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium Support"])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can receive differentiated services with Premium Support. Automated test experts by field provide priority support for scenario priority registration and changed functions."])},
          "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0.5M/M"])},
          "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1M/M"])},
          "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2M/M"])}
        },
        "option3": {
          "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Case Templete"])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Having trouble writing test scenarios? HBsmith will create a test scenario for you."])},
          "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 or less"])},
          "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["250 or less"])}
        },
        "option4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity verification text service"])},
        "option5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank deposit/withdrawal test"])}
      }
    },
    "enterprise": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise Plan"])},
      "content": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer-tailored service"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales Inquiry"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dedicated service manager management"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional benefits/discounts for bulk test plans"])},
        "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chrome, IE11, Firefox, Safari…Etc"])},
        "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dedicated Account Manager"])},
        "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA manpower support"])},
        "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real-time, historical dashboard"])},
        "text9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone and online notification support"])}
      },
      "option": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need more options?"])},
        "option1": {
          "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test environments, country-specific support, custom dashboards… Additional options can be negotiated."])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask for a quote"])}
        }
      }
    }
  },
  "footNavBar": {
    "menu": {
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service"])},
      "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
      "serviceStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Status"])}
    },
    "detail1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["HBsmith ", _interpolate(_named("sep")), " Business registration number: 291-88-00604"])},
    "detail2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Personal information protection manager: Jesang Yoon ", _interpolate(_named("sep")), " CEO: Jongwon Han"])},
    "detail3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tel:"])},
    "detail4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["070-4280-9333"])},
    "detail5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Head office: 108-403, 46, Gyeongsu-daero 623beon-gil, Dongan-gu, Anyang-si, Gyeonggi-do, Republic of Korea"])},
    "detail6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coworking office: 10F(WeWork), 518, Teheran-ro, Gangnam-gu, Seoul, Republic of Korea"])},
    "detail7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporation HBsmith"])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© 2023 All rights reserved."])}
  }
}