<template>
  <section id="main" class="sec_01 main">
    <div class="main_text">
      <div class="title">
        <h1>대한민국 1등 QA 테스트 자동화 아웃소싱</h1>
        <h3>
          • QA 테스트 자동화 구축 및 운영<br />
          • 서비스 관제 및 장애 알림 서비스<br />
          • 실시간 테스트 결과 대시보드<br />
          • 테스트 결과 버전관리 <br />
        </h3>
        <p>On-premise, SaaS(Software as a Service) 환경 제공</p>
      </div>
    </div>
    <div class="key_visual_area">
      <img src="@/assets/images/Visual_20220609.png" class="key_03_img" alt="빠르고 쉬운 QA 테스트 대행" />
    </div>

    <div class="count">
      <div>
        <p class="number">
          <b>
            2,637,000
          </b>시간
        </p>
        누적 QA 테스트
        <p class="mt-5">수행 시간</p>
      </div>
      <div>
        <p class="number">
          <b>
            2,210
          </b>개
        </p>
        누적 장애/버그 검출
      </div>
      <div>
        <p class="number">
          <b>
           8
          </b>년
        </p>
        비즈니스 경력
      </div>
    </div>
  </section>

  <!--동영상_2022.12-->
  <section class="sec_v">
    <div>
      <h2>HBsmith 서비스가 궁금하세요?</h2>
      <h4>
        QA 테스트 자동화 구축, 실시간 테스트 결과 대시보드, 테스트 결과 버전관리까지 한
        번에!<br />
        HBsmith와 함께 높은 서비스 품질을 확보하세요.
      </h4>
      <p>
        <iframe src="https://www.youtube.com/embed/CFn1Fmg3dWE" frameborder="0" autoplay="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </p>
    </div>
  </section>

  <!-- HBsmith 고객사 -->
  <section class="sec_02">
    <h2>대표 고객사</h2>
    <br>
    <div class="major_customer">
      <div class="customer_logo">
        <img src="@/assets/images/customer/samsungfire.png" alt="삼성화재" class="h90" />
      </div>
      <div class="customer_logo">
        <img src="@/assets/images/customer/ptkorea.png" alt="ptkorea" class="h90" />
      </div>
      <div class="customer_logo">
        <img src="@/assets/images/customer/adidas.png" alt="adidas" />
      </div>
      <div class="customer_logo">
        <img src="@/assets/images/customer/Daangn_Signature_RGB.png" alt="daangn" class="h80" />
      </div>
      <div class="customer_logo">
        <img src="@/assets/images/customer/customer-lfmall.webp" alt="LF몰" class="h80" />
      </div>
      <div class="customer_logo">
        <img src="@/assets/images/customer/customer-kbhc.webp" alt="KBhealthcare" class="h80" />
      </div>
      <div class="customer_logo">
        <img src="@/assets/images/customer/gsretail.svg" alt="gsretail" class="h80" />
      </div>
      <div class="customer_logo">
        <img src="@/assets/images/customer/customer-skmagic.webp" alt="SK매직" class="h120" />
      </div>
      <div class="customer_logo">
        <img src="@/assets/images/customer/customer-nshomeshopping.webp" alt="NS홈쇼핑" class="h90" />
      </div>
      <div class="customer_logo">
        <img src="@/assets/images/customer/customer-tmap@3x.webp" alt="Tmap" class="h90" />
      </div>
      <div class="customer_logo">
        <img src="@/assets/images/customer/hanwha.svg" style="height: 40px" alt="ebc" class="h80" />
        <p style="margin: 0px; font-size: 12px; padding-left: 45px">
          (EnterpriseBlockChain)
        </p>
      </div>
      <div class="customer_logo">
        <img src="@/assets/images/customer/customer-lotteon@3x.webp" alt="롯데온" />
      </div>
      <div class="customer_logo">
        <img src="@/assets/images/customer/customer_mohw.webp" alt="한국건강증진개발원" class="h90" />
      </div>
      <div class="customer_logo">
        <img src="@/assets/images/customer/customer-ihq.webp" alt="IHQ" />
      </div>




    </div>

    <div class="review_card">
      <div>
        <span><img src="@/assets/images/customer/customer-lotteon@3x.webp" height="25" alt="" /></span>
        <p class="words">
          “자동화 테스트는 서비스 신뢰성을 높여줍니다. HBsmith 서비스 도입으로 문제가 발생
          했을 때 알림을 받고있습니다. 정확하고 빠른 원인 파악으로 문제 해결 시간을
          단축했습니다.”
        </p>
      </div>
      <!--롯데온-->
      <div>
        <span><img src="@/assets/images/customer/customer-dogmate@3x.webp" height="25" alt="" /></span>
        <p class="words">
          “연휴 전에 중요한 기능 오픈을 해야 하는 일이 있었는데, 에러 발생 여부를 확인하고
          배포하여 평안한 연휴를 보낼 수 있었습니다”
        </p>
      </div>
      <!--도그메이트-->
      <div>
        <span><img src="@/assets/images/customer/customer-crema@3x.webp" height="25" alt="" /></span>
        <p class="words">
          “60단계 QA 테스트를 모두 인공지능 봇에게 맡기고, 이제는 신규 기능에 더 집중하고
          있습니다.”
        </p>
      </div>
      <!--크레마-->
      <div>
        <span><img src="@/assets/images/customer/customer-sixshop@3x.webp" height="25" alt="" /></span>
        <p class="words">
          “모든 기능을 손으로 테스트 했었습니다. HBsmith 서비스 도입 후 창의적인 업무에
          집중할 수 있어요.”
        </p>
      </div>
      <!--식스샵-->
    </div>
    <div class="review_more_btn">
      <a href="/review" class="j-primary-btn">이용 후기 더 보러 가기</a>
    </div>
  </section>

  <!--실기기환경정보_2023.02-->
  <section class="sec_idc">
    <h2>모바일 기기로 실제 사용자와 동일한 환경 제공</h2>
    <p>
      HBsmith는 IDC 센터에 모바일 디바이스 테스트 환경을 보유하고 있습니다.<br />
      Android, iOS 등 필요한 디바이스를 선택하세요.<br />
      그리고 선택한 디바이스로 기능 테스트, 모니터링을 해보세요.
    </p>
    <div class="hide-scr">
      <ul>
        <li><img src="@/assets/images/idc-img01.png" alt="" /></li>
        <li><img src="@/assets/images/idc-img02.png" alt="" /></li>
        <li><img src="@/assets/images/idc-img03.png" alt="" /></li>
      </ul>
    </div>
  </section>

  <!-- 서비스 소개_2022.06.10 -->
  <section class="sec_03">
    <h2 class="response_title">비즈니스에 필요한 HBsmith 제품을 확인해보세요.</h2>
    <div class="contents_area sec03_underline">
      <li><img src="@/assets/images/sec_03_service01.png" alt="" /></li>
      <li>
        <h4>테스트 자동화 솔루션</h4>
        <h3>Deep QA</h3>
        <p>설치, 코딩 없이 바로 시작하세요.</p>
        <p>24시간 365일 Web, App 환경을 제공합니다.</p>
        <p>테스트 자동화로 결함, 장애 알림을 받으세요. 빠르게 대처할 수 있습니다.</p>
        <p>신뢰 높은 테스트 과정, 결과 QA 이미지로 디버깅을 빠르게 하세요.</p>
        <img src="@/assets/deepqa001.png" style="width: 100%" alt="" />
        <p>그림. 모바일 디바이스 전화 통화 테스트</p>
      </li>
    </div>
    <div class="contents_area right sec03_underline">
      <li><img src="@/assets/images/sec_03_service02.png" alt="" /></li>
      <li>
        <h4>Web,App 로딩 속도 측정 서비스</h4>
        <h3>Deep Meter</h3>
        <p>로딩 속도가 1초 느리면, 전환율은 7% 감소합니다.</p>
        <p>설치, 코딩 없이 App 실행, 마이페이지, 결제 등 속도 측정 결과를 제공합니다.</p>
        <p>타사 앱과 속도 비교로 경쟁력을 확보하세요.</p>
        <img src="@/assets/images/deepmeter001.png" style="width: 100%" />
        <p>그림. 여러 쇼핑몰 앱 속도 비교 측정 결과 대시보드</p>
      </li>
    </div>
    <!-- <div class="contents_area right sec03_underline">
      <li><img src="@/assets/images/sec_03_service04.png" alt="" /></li>
      <li>
        <h4>웹 스크래핑 자동화 서비스</h4>
        <h3>Deep Scraper</h3>
        <p>인프라, 코딩 없이 웹 데이터를 수집할 수 있습니다.</p>
        <p>
          HBsmith 개별 봇은 짧은 시간에 많은 양의 데이터를 수집합니다. <br />단순 반복적인
          데이터 수집 부수 업무 자동화로 본연의 업무에 집중할 수 있습니다.
        </p>
      </li>
    </div>
    <div class="contents_area">
      <li><img src="@/assets/images/sec_03_service05.png" alt="" /></li>
      <li>
        <h4>사람이 기계처럼 일하지 않는 RPA 서비스</h4>
        <h3>Deep Macro</h3>
        <p>HBsmith 개별 봇이 엄청난 양의 단순 태스크를 처리합니다.</p>
        <p>
          사람은 가치 있는 일, 창의적인 일에 집중할 수 있습니다. Deep Macro는 조직의
          전략에 따라 운영하여 높은 효율성과 기업 이익 증대에 중요한 역할을 수행합니다.
        </p>
        <p>기존 업무 분석, 설계, 실행, 자동화 프로세스를 적용해보세요.</p>
      </li>
    </div> -->
  </section>

  <!--문의하기-->
  <section id="sec_08" class="sec_08 main-container">
    <h2>HBsmith 아웃소싱 문의</h2>
    <h3>QA 테스트 자동화 구축, 운영, 대시보드, 기타 문의</h3>
    <p>전화문의 070-4280-9333, 이메일문의 sales@hbsmith.io</p>
    <form class="apply_form" action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
      method="post" @submit="checkForm">
      <input type="hidden" name="oid" value="00D2v0000027LRb" />
      <input type="hidden" name="retURL" value="http://hbsmith.io" />
      <input type="hidden" name="first_name" id="first_name" />
      <input type="hidden" name="last_name" id="last_name" />

      <div class="form-control">
        <label>{{ $t("home.section8.name") }}</label>
        <input type="text" name="name" v-model="name" />
      </div>
      <div class="form-control">
        <label>{{ $t("home.section8.email") }}</label>
        <input type="text" name="email" id="email" v-model="email" />
      </div>
      <div class="form-control">
        <label>{{ $t("home.section8.phone") }}</label>
        <input type="text" name="mobile" id="mobile" v-model="mobile" />
      </div>
      <div class="form-control">
        <label>회사</label>
        <input type="text" name="company" id="company" />
      </div>
      <div class="form-control">
        <label>문의내용</label>
        <input type="textarea" name="description" />
      </div>
      <div class="agree_check">
        <div>
          <input type="checkbox" name="agreement_marketing" id="agreement_marketing" />
          <label for="agreement_marketing">{{ $t("home.section8.marketing") }}</label>
        </div>
        <div>
          <input type="checkbox" name="agreement_privacy" id="agreement_privacy" v-model="agreement_privacy" />
          <label for="agreement_privacy">{{ $t("home.section8.privacy") }}</label>
        </div>
      </div>
      <button type="submit" class="j-primary-btn" id="btn-submit">
        {{ $t("home.section8.submit") }}
      </button>
    </form>
  </section>
</template>

<script>
import $ from 'jquery';
import { nextTick } from 'vue';

require('../../assets/slick-1.8.1/slick/slick.min');

export default {
  data: () => ({
    name: '',
    email: '',
    mobile: '',
    agreement_privacy: '',
  }),
  mounted: () => {
    $('.service_tabs .btn_group button').click((e) => {
      const target = $(e.target).attr('class');
      $('.service_tabs .btn_group button').removeClass('active');
      $('.service_tabs .contents .btn').removeClass('active');
      $(`.service_tabs .btn_group button.${target}`).addClass('active');
      $(`.box_${target}`).addClass('active');
    });

    nextTick(() => {
      $('.post-wrapper').slick({
        slidesToShow: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
        dots: true,
      });
    });

    const { hash } = window.location;
    if (hash && hash === '#sec_08') {
      $('html, body').animate({ scrollTop: $(document).height() }, 500);
    }

    const path = location.href;
    const { referrer } = document;
    $('input[name=source]').val(`path: ${path}, referrer: ${referrer}`);
  },
  methods: {
    checkForm(e) {
      $('#last_name').val(this.name.slice(0, 1));
      $('#first_name').val(this.name.slice(1));

      if (this.name && this.email && this.mobile && this.agreement_privacy) {
        alert(`${this.name}님 문의 고맙습니다. 연락 드리겠습니다.`);
        document.getElementById('btn-submit').disabled = true;
        // ga
        dataLayer.push({ event: 'purchase' });
      } else {
        e.preventDefault();
        alert('필수 값 확인 부탁드립니다.');
        if (!this.name) {
          document.getElementsByName('name')[0].focus();
        }
        if (!this.email) {
          document.getElementsByName('email')[0].focus();
        }
        if (!this.mobile) {
          document.getElementsByName('mobile')[0].focus();
        }
      }
    },
    theFormat(number) {
      return number
        .toFixed(0)
        .toString()
        .replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  },
};
</script>

<style scoped>
.sec_05 .service_tabs,
img {
  width: auto;
}

.hide {
  display: none;
}
</style>
<style src="@/assets/slick-1.8.1/slick/slick-theme.css"></style>
<style src="@/assets/slick-1.8.1/slick/slick.css"></style>
